// TODO: Fix the  navigation code

.nav {
    list-style-type: none;
    margin: 0 auto;

    li {
        position: relative;
        display: inline-block;
        padding: 0;
        border-radius: $border-radius;

        &:hover {
            background-color: $overlay-03;

            a {
                cursor: pointer;
            }
        }

        a {
            display: block;
            padding: 10px 20px;
            position: relative;
            color: inherit;
            font-size: $font-size-base;
        }

        .nav__dropdown__list {
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            display: block;

            li {
                padding: 2px 0;
            }
        }
    }
}


.ic {
    position: fixed;
    cursor: pointer;
    display: inline-block;
    right: 25px;
    width: 32px;
    height: 24px;
    text-align: center;
    top: 0px;
    outline: none;

    &.menu {
        top: 25px;
        z-index: 20;

        .line {
            height: 4px;
            width: 100%;
            display: block;
            margin-bottom: 6px;
        }

        .line-last-child {
            margin-bottom: 0px;
        }
    }

    &.close {
        opacity: 0;
        font-size: 0px;
        font-weight: 300;
        color: $black-100;
        top: 8px;
        height: 40px;
        display: block;
        outline: none;
    }
}


@media only screen and (max-width:768px) {
    .nav-head {
        color: orange;
    }

    .ic {
        &.menu {
            display: block;

            .line {
                background-color: rgba(0, 0, 0, 0.5);
                border: 1px solid $white-100;
                border-radius: 25px;
            }

            &:focus,
            &:hover {
                opacity: 1;
            }

            &:focus {
                .line {
                    background-color: $white-overlay-20 !important;
                }

                .line:nth-child(1) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                .line:nth-child(2) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    margin-top: -10px;
                }

                .line:nth-child(3) {
                    transform: translateY(15px);
                    opacity: 0;
                }
            }
        }
    }

    .ic.menu:focus~.ic.close {
        opacity: 1;
        z-index: 21;
        outline: none;
    }

    .nav {
        z-index: 2;
        padding: 50px 0;
        position: fixed;
        right: 0px;
        top: 0px;
        width: 0px;
        background-color: $black-100;
        height: 100%;
        overflow: auto;
        /*CSS animation applied : Slide from Right*/
        -webkit-transition-property: background, width;
        -moz-transition-property: background, width;
        -o-transition-property: background, width;
        transition-property: background, width;
        -webkit-transition-duration: 0.6s;
        -moz-transition-duration: 0.6s;
        -o-transition-duration: 0.6s;
        transition-duration: 0.6s;

        .nav__dropdown {
            .nav-head {
                display: none;
            }
        }

        >* {
            -webkit-transition-property: opacity;
            -moz-transition-property: opacity;
            -o-transition-property: opacity;
            transition-property: opacity;
            -webkit-transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
            opacity: 0;
        }

        li {
            a {
                font-weight: 600;
                color: $white-100;

                &:after {
                    display: none;
                }
            }

            &:hover {
                >a {
                    color: $black-100;
                    text-decoration: none;
                    font-weight: 600;
                }
            }
        }

        li:first-child {
            border-radius: 0px;
        }

        >li {
            display: block;
            border-bottom: 1px solid #444;

            &:hover {
                background-color: transparent;
            }

            .nav__dropdown__list {
                >li {
                    a {
                        display: block;
                        color: #eee;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .ic.menu:focus~.nav {
        width: 300px;
        background-color: rgba(0, 0, 0, 1);
    }

    .ic.menu:focus~.nav>* {
        opacity: 1;
    }

    .nav-head {
        font-size: 16px;
    }

    .ic.menu:focus~.nav>li>div.nav__dropdown {
        position: relative;
        visibility: visible;
        opacity: 1.0;
    }
}

@media only screen and (min-width:769px) {
    .ic.menu {
        display: none;
    }

    .nav {
        .nav-head {
            margin-left: 10px;
            margin-bottom: 10px;
        }

        display: block;
        position: relative;

        .nav__dropdown {
            padding: 15px;
            border-radius: $border-radius;

            .nav-head {
                font-size: 20px;
            }
        }

        >li {
            >a {
                &:after {
                    background-color: #999;
                }
            }

            &:hover {
                .nav__dropdown {
                    background-color: #f9f9f9;
                    visibility: visible;
                    opacity: 1;
                    -webkit-transform: rotateX(0deg);
                    -moz-transform: rotateX(0deg);
                    -ms-transform: rotateX(0deg);
                    transform: rotateX(0deg);

                    >* {
                        opacity: 1;
                    }
                }

                >.mega-menu {
                    .ri {
                        -webkit-transform: rotate(-180deg);
                        -moz-transform: rotate(-180deg);
                        -ms-transform: rotate(-180deg);
                        transform: rotate(-180deg);
                    }
                }
            }

            .nav__dropdown {
                visibility: hidden;
                background-color: #f9f9f9;
                position: absolute;
                margin-top: 0px;
                color: $black-80;
                right: 10px;
                z-index: 3;
                font-size: 16px;
                border: 1px solid $overlay-08;
                box-shadow: 0 4px 8px $overlay-08;
                opacity: 0;
                min-width: 300px;

                /*CSS animation applied for sub menu : Slide from Top */
                -webkit-transition: all 0.4s ease 0s;
                -o-transition: all 0.4s ease 0s;
                transition: all 0.4s ease 0s;
                -webkit-transform: rotateX(90deg);
                -moz-transform: rotateX(90deg);
                -ms-transform: rotateX(90deg);
                transform: rotateX(90deg);
                -webkit-transform-origin: top center;
                -ms-transform-origin: top center;
                transform-origin: top center;

                >* {
                    -webkit-transition-property: opacity;
                    -moz-transition-property: opacity;
                    -o-transition-property: opacity;
                    transition-property: opacity;
                    -webkit-transition-duration: 0.4s;
                    -moz-transition-duration: 0.4s;
                    -o-transition-duration: 0.4s;
                    transition-duration: 0.4s;
                    opacity: 0;
                }

                .nav__dropdown__list {
                    li {
                        display: block;
                    }
                }
            }
        }
    }
}

.nav-vertical {
    list-style: none;

    .nav-vertical__heading {
        margin-bottom: 20px;
    }
}