footer {
    background-color: $base;
    color: $white-100;
    padding: 100px 20px;

    ul {
        padding-left: 15px;
    }

    * {
        color: $white-100;
    }

    .copyright {
        margin-top: 50px;
        font-size: $font-size-sm;
    }
}