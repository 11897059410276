.btn {
    box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, .1);
    border-radius: $border-radius;
    margin-right: 10px;
    transition: all 0.3s;
    // border: 1px solid $overlay-10;
    padding: 15px 25px;
    cursor: pointer;
    background-color: $blue;
    color: $white-100 !important;

    &.btn-white {
        background-color: $white-100;
        color: $blue !important;
    }

    &:hover {
        background-color: $black-100 !important;
        color: $white-100 !important;
    }

    .ri {
        margin-left: 5px;
    }
}